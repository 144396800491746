import { Metaplex, walletAdapterIdentity } from '@metaplex-foundation/js';
import { useWallet } from 'solana-wallets-vue';
import { Connection, clusterApiUrl } from "@solana/web3.js";
import { useCommonStore } from '@/store';

let metaplex = null;

export const useMetaplex = () => metaplex;

export const initMetaplex = () => {

    const store = useCommonStore();
    const network = store.solanaNetwork;

    let clusterUrl = clusterApiUrl(network);
    if (network == 'mainnet-beta') {
        // clusterUrl = 'https://icy-morning-waterfall.solana-mainnet.quiknode.pro/1d4818ff9d1be4c175581c59ddcff56efd9731e6/';
        clusterUrl = 'https://rachel-wirhfr-fast-mainnet.helius-rpc.com/';
        // clusterUrl = 'https://api.metaplex.solana.com/';
    }

    const connection = new Connection(clusterUrl, "confirmed");
    const wallet = useWallet();
    if (wallet) {
        metaplex = Metaplex.make(connection).use(walletAdapterIdentity(wallet));
    } else {
        metaplex = Metaplex.make(connection)
    }
}