import axios from "axios";
import { apiData } from "./useCommon";
import { useNotifyStore } from "@/store";
import { ElMessage } from "element-plus";

export const convertNotifyType = (element, lang = 'zh-TW') => {

    if (element.notify_type == 'like_article') {

        const like_count = element.notify_content.like_count - 1;
        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            if (like_count != 0) {
                notify_msg += ' 及 其他' + like_count.toString() + '人'
            }
            notify_msg += ' 按你的文章讚'

        } else if (lang == 'en') {

            if (like_count != 0) {
                if (like_count == 1) {
                    notify_msg += ' and ' + like_count.toString() + ' other'
                } else {
                    notify_msg += ' and ' + like_count.toString() + ' others'
                }
            }
            notify_msg += ' like your article.'

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            article_id: element.notify_content.article_id,
            profile_picture: element.notify_content.by.profile_picture,
        }

    } else if (element.notify_type == 'like_comment') {

        const like_count = element.notify_content.like_count - 1

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            if (like_count != 0) {
                notify_msg += ' 及 其他' + like_count.toString() + '人'
            }
            notify_msg += ' 按你的留言讚'

        } else if (lang == 'en') {

            if (like_count != 0) {
                if (like_count == 1) {
                    notify_msg += ' and ' + like_count.toString() + ' other'
                } else {
                    notify_msg += ' and ' + like_count.toString() + ' others'
                }
            }
            notify_msg += ' like your comment.'

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            article_id: element.notify_content.article_id,
            comment_id: element.notify_content.comment_id,
            profile_picture: element.notify_content.by.profile_picture
        }

    } else if (element.notify_type == 'comment_tag') {

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            notify_msg += ' 在一則留言中標註了你';

        } else if (lang == 'en') {

            notify_msg += ' tagged you in a comment.';

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            article_id: element.notify_content.article_id,
            comment_id: element.notify_content.comment_id,
            profile_picture: element.notify_content.by.profile_picture,
            by_user_id: element.notify_content.by.user_id
        }

    } else if (element.notify_type == 'send_email') {

        const email_count = element.notify_content.email_count - 1;

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            if (email_count != 0) {
                notify_msg += ' 及 其他' + email_count.toString() + '人'
            }
            notify_msg += ' 發送訊息給你'

        } else if (lang == 'en') {

            if (email_count != 0) {

                if (email_count == 1) {
                    notify_msg += ' and ' + email_count.toString() + ' other send you messages.'
                } else {
                    notify_msg += ' and ' + email_count.toString() + ' others send you messages.'
                }

            } else {
                notify_msg += ' send you a message.'
            }

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            profile_picture: element.notify_content.by.profile_picture,
            by_user_id: element.notify_content.by.user_id
        }

    } else if (element.notify_type == 'friend_invite') {

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            notify_msg += ' 發送好友邀請給你';

        } else if (lang == 'en') {

            notify_msg += ' send you a friend request.';

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            profile_picture: element.notify_content.by.profile_picture,
            by_user_id: element.notify_content.by.user_id
        }

    } else if (element.notify_type == 'been_friend') {

        let notify_msg;
        if (lang == 'zh-TW') {

            notify_msg = '您已與 '
            notify_msg += element.notify_content.by.username;
            notify_msg += ' 成為好友'

        } else if (lang == 'en') {

            notify_msg = 'You have been friends with '
            notify_msg += element.notify_content.by.username;

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            profile_picture: element.notify_content.by.profile_picture,
            by_user_id: element.notify_content.by.user_id
        }

    } else if (element.notify_type == 'reply_article') {

        const reply_count = element.notify_content.reply_count - 1;

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            if (reply_count != 0) {
                notify_msg += ' 及 其他' + reply_count.toString() + '人'
            }
            notify_msg += ' 回覆了你的文章'

        } else if (lang == 'en') {

            if (reply_count != 0) {
                if (reply_count == 1) {
                    notify_msg += ' and ' + reply_count.toString() + ' other'
                } else {
                    notify_msg += ' and ' + reply_count.toString() + ' others'
                }
            }
            notify_msg += ' replied your article.'

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            article_id: element.notify_content.article_id,
            profile_picture: element.notify_content.by.profile_picture
        }

    } else if (element.notify_type == 'sub_user') {

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            notify_msg += ' 已訂閱你';

        } else if (lang == 'en') {

            notify_msg += ' has subscribed you.';

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            profile_picture: element.notify_content.by.profile_picture,
            by_user_id: element.notify_content.by.user_id
        }

    } else if (element.notify_type == 'publish_article') {

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            notify_msg += ' 發布了一則新文章';

        } else if (lang == 'en') {

            notify_msg += ' posted a new article.';

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            article_id: element.notify_content.article_id,
            profile_picture: element.notify_content.by.profile_picture,
            by_user_id: element.notify_content.by.user_id
        }

    } else if (element.notify_type == 'article_tag') {

        let notify_msg = element.notify_content.by.username;

        if (lang == 'zh-TW') {

            notify_msg += ' 在一則文章中標註了你';

        } else if (lang == 'en') {

            notify_msg += ' tagged you in an article.';

        }

        return {
            notify_type: element.notify_type,
            notify_msg: notify_msg,
            create_at: element.Created_At,
            isRead: element.isRead,
            article_id: element.notify_content.article_id,
            profile_picture: element.notify_content.by.profile_picture,
            by_user_id: element.notify_content.by.user_id
        }
    }

}

export const getNotifyHeader = async (lang = 'zh-TW') => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'get',
        url: apiBaseurl + '/notify/header',
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {

        const store = useNotifyStore();
        const result = response.data.Result;

        if (result.notifyData) {

            store.notifications = result.notifyData.notifications;

            store.notifyHeaderData = [];
            result.notifyData.notify_history.forEach((element) => {
                store.notifyHeaderData.push(convertNotifyType(element, lang));
            });

        }

        store.ifBonkTime = result.ifBonkTime;

    });
}

export const getNotifyList = async (page, lang = 'zh-TW') => {

    const store = useNotifyStore();
    const { userToken, apiBaseurl } = apiData();

    axios({
        method: 'get',
        url: apiBaseurl + '/notify/list',
        headers: {
            Authorization: "Bearer " + userToken
        },
        params: {
            page: page
        }
    }).then(response => {

        const result = response.data.Result;

        store.total_items = result.total_items;

        store.notifyHistoryData = [];
        result.items.forEach((element) => {
            store.notifyHistoryData.push(convertNotifyType(element, lang));
        });

    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

// 點擊notify，並更新最後一次查看時間
export const checkNotify = async (notifyOpened) => {

    if (!notifyOpened) {

        const { userToken, apiBaseurl } = apiData();
        const store = useNotifyStore();

        await axios({
            method: 'post',
            url: apiBaseurl + '/notify/check',
            headers: {
                Authorization: "Bearer " + userToken
            }
        });

        store.notifications = "0";
        notifyOpened = true;
        return notifyOpened;

    } else {
        console.log('hihi');
    }
}

export const readToPage = async (type, article_id, comment_id, by_user_id, isread) => {

    const { userToken, apiBaseurl } = apiData();

    if (!isread) {

        await axios({
            method: 'post',
            url: apiBaseurl + '/notify/isread',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                notify_type: type,
                article_id: article_id,
                comment_id: comment_id,
                by_user_id: by_user_id
            }
        }).catch(error => {
            const error_msg = error.response.data;
            ElMessage({
                showClose: true,
                type: "error",
                message: error_msg.Status.Desc,
                duration: 1500
            });
        })

    }

    const articleType = ['like_article', 'reply_article', 'publish_article', 'article_tag'];

    if (articleType.includes(type)) {
        window.location.href = '/article/' + article_id.toString();
    } else if (type == 'like_comment' || type == 'comment_tag') {
        window.location.href = '/article/' + article_id.toString() + '?c=' + comment_id;
    } else if (type == 'send_email') {
        window.location.href = '/mail';
    } else if (type == 'been_friend') {
        window.location.href = '/manage_friend';
    } else if (type == 'sub_user') {
        window.location.href = '/manage_sub';
    }

}

export const isReadMultiple = async (multipleSelection, notifyTable) => {

    const store = useNotifyStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "post",
        url: apiBaseurl + '/notify/isread/multiple',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            notify_list: multipleSelection
        }
    }).then(() => {

        multipleSelection.forEach(item => {
            if (!item.isRead) {
                const objIndex = store.notifyHistoryData.findIndex((obj => obj == item));
                store.notifyHistoryData[objIndex].isRead = true;
            }
        });
        notifyTable.clearSelection();

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    })

}