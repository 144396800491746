import { useCommonStore, useUserStore } from "@/store";
import axios from "axios";
import { ElMessage } from "element-plus";

export const apiData = () => {

    const store = useCommonStore()

    return {
        userToken: window.$cookies.get("userToken"),
        apiBaseurl: store.apiBaseurl
    }
}

export const ContainDefaultTag = (tag, tags) => {
    return tags.indexOf(tag) > -1
}

// 確認是否有無破產
export const checkRule = (portfolio) => {
    console.log(portfolio);
}

// 搜尋結果
export const getSearchList = async (page) => {

    const { apiBaseurl } = apiData();

    const commonStore = useCommonStore();
    const search_name = commonStore.search.name;
    const search_type = commonStore.search.type;

    await axios({
        method: "get",
        url: apiBaseurl + "/search",
        params: {
            type: search_type,
            search_name: search_name,
            page: page
        }
    }).then(response => {

        const result = response.data.Result;
        commonStore.search.result = result.items;
        commonStore.search.total_items = result.total_items;
        document.getElementById("search-top").scrollIntoView();

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.response.data,
            duration: 1500
        });

    })

}

export const getLatestPrice = async (symbol = 'SOLUSDT') => {

    const url = `https://api.bitget.com/api/v2/spot/market/tickers?symbol=${symbol}`;

    const response = await axios.get(url);

    const lastPr = parseFloat(response.data.data[0].lastPr);

    return lastPr;

}