import axios from "axios";
import { apiData } from "./useCommon";
import { useUserStore, useGameStore, useCommonStore } from "@/store";
import { ElMessage } from "element-plus";
import { getOHLCData, groupDailytoMonthly, groupDailytoWeekly, groupHourtoDaily, groupMinutetoHour, groupMinutetoMinute } from "./calOHLC";
import { ref } from "vue";

export const getGameDetail = async (gameId) => {

    const userStore = useUserStore();
    const gameStore = useGameStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + "/game/detail",
        headers: {
            "Authorization": "Bearer " + userToken
        },
        params: {
            game_id: gameId
        }
    }).then(response => {

        const result = response.data.Result;
        const user_data = result.user_data;

        userStore.username = user_data.username;
        userStore.portfolio = user_data.portfolio;
        userStore.convert_portfolio = user_data.convert_portfolio;
        userStore.profile_picture = user_data.profile_picture;
        userStore.rank = user_data.rank;
        userStore.in_game = user_data.in_game;
        userStore.level = user_data.level;
        userStore.asset_level = user_data.asset_level;
        userStore.user_id = user_data.user_id;
        userStore.tt_coin = user_data.tt_coin;

        gameStore.game_data = result.game_data;
        gameStore.game_mode = result.game_mode;

    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

export const startGame = async (game_type, lang = 'zh-TW', competitionId = null) => {

    const gameStore = useGameStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "post",
        url: apiBaseurl + "/game",
        headers: {
            "Authorization": "Bearer " + userToken
        },
        data: {
            game_type: game_type,
            lang: lang,
            id: competitionId
        }
    }).then(response => {

        const result = response.data.Result;

        gameStore.game_data = result.game_data;
        gameStore.game_mode = result.game_mode;

    }).catch(error => {

        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

        if (competitionId) {
            window.location.href = '/competition/info/' + competitionId;
        } else {
            window.location.href = '/game_lobby';
        }

    })

}

export const endGame = async (competitionId) => {

    const gameStore = useGameStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        methods: "get",
        url: apiBaseurl + "/game/result",
        headers: {
            Authorization: "Bearer " + userToken
        },
        params: {
            id: competitionId
        }
    }).then(response => {

        const result = response.data.Result;
        const game_result = result.game_result;

        if (game_result != null) {

            gameStore.game_result = game_result;

            if (game_result.awards_list.length > 0) {
                const commonStore = useCommonStore();
                commonStore.awards.data = game_result.awards_list;
                commonStore.awards.toggle = !commonStore.awards.toggle;
            }

        } else {
            if (competitionId) {
                window.location.href = '/competition/info/'+competitionId;
            } else {
                window.location.href = '/game_lobby';
            }
        }
    })
}

// 查看是否有足夠現金遊玩遊戲
export const checkPortfolioRule = (applyLoanToggle, gameMode) => {

    const userStore = useUserStore();
    const portfolio = userStore.portfolio;
    const isGenesis = userStore.isGenesis;


    const commonStore = useCommonStore();
    const localeSetting = commonStore.localeSetting;

    if (portfolio < 100000) {

        applyLoanToggle = !applyLoanToggle;
        return applyLoanToggle;

    } else {

        if ((['TX_1T', 'TX_5T'].includes(gameMode) || gameMode.includes('NQ') || gameMode.includes('CL1') || gameMode.includes('GC1')) && isGenesis == false) {

            let message = "您必須是鎚圈NFT擁有者才能遊玩此遊戲";
            if (localeSetting == 'en') {
                message = "You must be TradingTrain's NFT holder to play this game";
            }

            ElMessage({
                showClose: true,
                type: "error",
                message: message,
                duration: 1500
            });

        } else {
            window.location.href = '/game?mode=' + gameMode;
        }
    }

}

// 取得參數設定
export const getGameParams = async () => {

    const { userToken, apiBaseurl } = apiData();
    const gameStore = useGameStore();

    await axios({
        method: "get",
        url: apiBaseurl + "/game/get_params",
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {
        gameStore.game_params = response.data.Result;
    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

// 儲存參數設定
export const setGameParams = async () => {

    const { userToken, apiBaseurl } = apiData();
    const gameStore = useGameStore();

    await axios({
        method: "post",
        url: apiBaseurl + "/game/set_params",
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: gameStore.game_params
    }).then(() => {
        console.log('更新參數成功');
    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

// 播放音效 function
export const playAudio = (type) => {
    let audio
    if (type == 'next') {
        audio = new Audio(require("../assets/music/button.mp3"))
    } else if (type == 'win') {
        audio = new Audio(require("../assets/music/makemoney.mp3"))
    } else if (type == 'lose') {
        audio = new Audio(require("../assets/music/losemoney.mp3"))
    }
    audio.play();
}

// 快捷鍵操作
export const buttonAction = (event, tradeFunc, tradeBtnReady, enableBuy,
    enableSell, chart, isMuted, toggleGameOverModal) => {
    // 有符合快捷鍵的 list
    const tradeBtnList = [' ', 'b', 's'];
    const rangeBtnList = ['1', '2', '3', '4', '5', '6'];
    const settingBtnList = ['m', 'e'];

    const key = event.key.toLowerCase();

    if (tradeBtnList.includes(key)) {

        // 如果是空白鍵，避免使用 default 功能
        if (key == ' ') {
            event.preventDefault();
        }

        if (tradeBtnReady) {
            if (key == ' ') {
                tradeFunc('next');
            } else if (key == 'b' && enableBuy) {
                tradeFunc('buy');
            } else if (key == 's' && enableSell) {
                tradeFunc('sell');
            }
        }

    } else if (rangeBtnList.includes(key)) {

        if (tradeBtnReady) {
            if (key == '1') {
                chart.rangeSelector.clickButton(0, chart.rangeSelector.buttonOptions[0], true);
            } else if (key == '2') {
                chart.rangeSelector.clickButton(1, chart.rangeSelector.buttonOptions[0], true);
            } else if (key == '3') {
                chart.rangeSelector.clickButton(2, chart.rangeSelector.buttonOptions[0], true);
            } else if (key == '4') {
                chart.rangeSelector.clickButton(3, chart.rangeSelector.buttonOptions[0], true);
            } else if (key == '5') {
                chart.rangeSelector.clickButton(4, chart.rangeSelector.buttonOptions[0], true);
            } else if (key == '6') {
                chart.rangeSelector.clickButton(5, chart.rangeSelector.buttonOptions[0], true);
            }
        }

    } else if (settingBtnList.includes(key)) {

        if (tradeBtnReady) {
            if (key == 'e') {
                toggleGameOverModal.value = !toggleGameOverModal.value
            } else if (key == 'm') {
                isMuted.value = !isMuted.value
            }
        }

    }
}

// 新增 yAxis
export const addYaxis = async (chart, indicatorId) => {

    // 取得新增一張圖之高度
    const add_height = 20;
    // 取得目前 YAxis 之數量
    const yAxis_num = chart.yAxis.length - 1;

    // 更新最上方 K 線之高度
    const init_percent = 80 - (yAxis_num - 1) * add_height;
    await chart.yAxis[0].update({
        height: init_percent.toString() + '%'
    });

    await chart.yAxis[1].update({
        top: init_percent.toString() + '%'
    });

    const modifyAxis = chart.yAxis.filter(elem => elem.userOptions.id !== 'navigator-y-axis');
    console.log(modifyAxis);

    // 因最上方K線高度調整，因此下方原有之 YAxis 相對位置也須跟著調整
    for (let i = 2; i <= modifyAxis.length - 1; i++) {

        const axis_top = init_percent + (i - 1) * add_height;

        await modifyAxis[i].update({
            top: axis_top.toString() + '%'
        })

    }

    // 取得最新新增指標之相對位置
    const add_top = init_percent + (yAxis_num - 1) * add_height

    // 新增 Y Axis
    console.log(indicatorId + '_axis');
    await chart.addAxis({
        id: indicatorId + '_axis',
        labels: {
            align: 'left',
        },
        top: add_top.toString() + '%',
        height: add_height.toString() + '%',
        offset: 0,
        gridLineColor: 'black'
    });

    return yAxis_num;
}

// 初始化指標
export const initIndicator = async (chart, indicateSetting, usedIndicator, upColor, downColor, isMulti = false) => {

    if (!chart) {
        setTimeout(() => {
            initIndicator()
        }, 100);
    } else {
        for (let i = 0; i < indicateSetting.length; i++) {

            const item = indicateSetting[i];

            if (["macd", "rsi", "stochastic"].includes(item.detail.type)) {
                const yAxis_num = await addYaxis(chart, item.indicate_id);
                item.detail.yAxis = yAxis_num;
            }

            if (item.detail.type == "macd") {
                item.detail.zones = [{
                    value: 0,
                    color: downColor
                }, {
                    color: upColor
                }]
            }

            await chart.addSeries(item.detail);

            let params;
            if (item.detail.type == "macd") {
                params = item.detail.params
            } else if (item.detail.type == "stochastic") {
                params = {
                    k_period: item.detail.params.periods[0],
                    d_period: item.detail.params.periods[1]
                }
            } else {
                params = {
                    period: item.detail.params.period
                }
            }

            if (!isMulti) {
                usedIndicator.push({
                    indicate_type: item.detail.type,
                    indicate_id: item.indicate_id,
                    params: params
                });
            }

        }
    }
}

// 新增指標 function
export const addIndicator = async (
    chart, kdData, macdData, maData,
    responseMsg, selectIndicator, usedIndicator,
    upColor, downColor, multiChart = null, competitionId = null) => {

    let indicatorId, add_series;

    if (selectIndicator == 'Stochastic') {

        // 判斷是否 > 0
        if (kdData.k_period <= 0 || kdData.d_period <= 0) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.lessThanZero,
                duration: 1500
            });
            return;
        }

        // 判斷是否有顏色
        if (!kdData.kLineColor || !kdData.dLineColor) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.selectColor,
                duration: 1500
            });
            return;
        }

        indicatorId = selectIndicator.toLowerCase();
        indicatorId += kdData.k_period.toString() + '_';
        indicatorId += kdData.d_period.toString();

        // 確認指標是否重複
        const existData = usedIndicator.filter((element) => {
            return element.indicate_id == indicatorId
        });
        if (existData.length > 0) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.indicatorExisted,
                duration: 1500
            });
            return;
        }

        const yAxis_num = await addYaxis(chart, indicatorId);
        if (multiChart) {
            await addYaxis(multiChart, indicatorId);
        }

        add_series = {
            id: indicatorId,
            type: selectIndicator.toLowerCase(),
            linkedTo: "stock-ohlc",
            params: {
                periods: [kdData.k_period, kdData.d_period]
            },
            yAxis: yAxis_num,
            color: kdData.kLineColor,
            lineWidth: 1,
            smoothedLine: {
                styles: {
                    lineColor: kdData.dLineColor
                }
            },
            marker: {
                enabled: false
            }
        }

        usedIndicator.push({
            indicate_type: selectIndicator.toLowerCase(),
            indicate_id: indicatorId,
            params: {
                k_period: kdData.k_period,
                d_period: kdData.d_period,
            }
        });

    } else if (selectIndicator == "MACD") {

        // 判斷是否 > 0
        if (macdData.period <= 0 || macdData.short_period <= 0 || macdData.long_period <= 0 || macdData.signal_period <= 0) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.lessThanZero,
                duration: 1500
            });
            return;
        }

        // 判斷是否有顏色
        if (!macdData.macdLineColor || !macdData.signalLineColor) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.selectColor,
                duration: 1500
            });
            return;
        }

        indicatorId = selectIndicator.toLowerCase();
        indicatorId += macdData.period.toString() + '_';
        indicatorId += macdData.long_period.toString() + '_';
        indicatorId += macdData.short_period.toString() + '_';
        indicatorId += macdData.signal_period.toString();

        // 確認指標是否重複
        const existData = usedIndicator.filter((element) => {
            return element.indicate_id == indicatorId
        });
        if (existData.length > 0) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.indicatorExisted,
                duration: 1500
            });
            return;
        }

        const yAxis_num = await addYaxis(chart, indicatorId);
        if (multiChart) {
            await addYaxis(multiChart, indicatorId);
        }

        add_series = {
            id: indicatorId,
            type: selectIndicator.toLowerCase(),
            linkedTo: 'stock-ohlc',
            params: {
                index: 3,
                period: macdData.period,
                longPeriod: macdData.long_period,
                shortPeriod: macdData.short_period,
                signalPeriod: macdData.signal_period
            },
            yAxis: yAxis_num,
            zones: [{
                value: 0,
                color: downColor
            }, {
                color: upColor
            }],
            signalLine: {
                styles: {
                    lineColor: macdData.signalLineColor,
                    lineWidth: 1
                }
            },
            macdLine: {
                styles: {
                    lineColor: macdData.macdLineColor,
                    lineWidth: 1
                }
            },
            marker: {
                enabled: false
            }
        }

        usedIndicator.push({
            indicate_type: selectIndicator.toLowerCase(),
            indicate_id: indicatorId,
            params: {
                period: macdData.period,
                longPeriod: macdData.long_period,
                shortPeriod: macdData.short_period,
                signalPeriod: macdData.signal_period
            }
        });

    } else {

        // 判斷是否 > 0
        if (maData.period <= 0) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.lessThanZero,
                duration: 1500
            });
            return;
        }

        // 判斷是否有顏色
        if (!maData.color) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.selectColor,
                duration: 1500
            });
            return;
        }

        indicatorId = selectIndicator.toLowerCase();
        indicatorId += maData.period.toString();

        // 確認指標是否重複
        const existData = usedIndicator.filter((element) => {
            return element.indicate_id == indicatorId
        });
        if (existData.length > 0) {
            ElMessage({
                showClose: true,
                type: 'error',
                message: responseMsg.indicatorExisted,
                duration: 1500
            });
            return;
        }

        let yAxis_num;
        if (selectIndicator == "RSI") {
            yAxis_num = await addYaxis(chart, indicatorId);
            if (multiChart) {
                await addYaxis(multiChart, indicatorId);
            }
        } else {
            yAxis_num = 0;
        }

        add_series = {
            id: indicatorId,
            type: selectIndicator.toLowerCase(),
            linkedTo: "stock-ohlc",
            color: maData.color,
            params: {
                period: maData.period,
                index: 3,
                decimals: 4
            },
            marker: {
                enabled: false
            },
            yAxis: yAxis_num,
        }

        console.log(yAxis_num);

        usedIndicator.push({
            indicate_type: selectIndicator.toLowerCase(),
            indicate_id: indicatorId,
            params: {
                period: maData.period
            }
        });

    }

    // 新增至 chart series 陣列當中
    await chart.addSeries(add_series);

    if (multiChart) {
        await multiChart.addSeries(add_series);
    }

    const addIndicatorData = {
        indicate_id: indicatorId,
        detail: add_series
    }

    const usedIndicatorData = usedIndicator;

    // 最後呼叫API儲存至資料庫
    const { apiBaseurl, userToken } = apiData();
    await axios({
        method: "post",
        url: apiBaseurl + "/game/indicate/insert",
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            indicate: addIndicatorData,
            id: competitionId
        }
    }).then(() => {
        ElMessage({
            showClose: true,
            message: responseMsg.addIndicator,
            type: 'success',
            duration: 1500
        });

    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: 'error',
            message: error_msg.Status.Desc,
            duration: 1500
        });
    });

    return {
        usedIndicatorData,
        addIndicatorData
    };
}

// 刪除指標 function
export const deleteIndicator = async (chart, indicate_id, indicate_type, usedIndicator, multiChart = null, callApi = true, competitionId = null) => {

    const removeFunc = async (chart) => {

        await chart.get(indicate_id).remove();

        if (["rsi", "macd", "stochastic"].includes(indicate_type)) {

            const del_elem = await chart.get(indicate_id + '_axis');

            const add_height = 20

            // 更新最上方 K 線之高度
            const init_percent = parseInt(
                chart.yAxis[0].userOptions.height.slice(0, 2)
            ) + add_height;
            console.log(init_percent, 'init_percent');

            await chart.yAxis[0].update({
                height: init_percent.toString() + '%'
            });

            await chart.yAxis[1].update({
                top: init_percent.toString() + '%'
            });

            await del_elem.remove();

            const yAxisList = chart.yAxis.filter(elem => elem.userOptions.id != 'navigator-y-axis');

            for (let i = 2; i <= yAxisList.length - 1; i++) {

                console.log(yAxisList[i].userOptions.id);

                if (yAxisList[i].userOptions.id != 'navigator-y-axis') {

                    let initHeight = 0;
                    if (yAxisList[i].userOptions.top) {
                        initHeight = parseInt(
                            yAxisList[i].userOptions.top.slice(0, 2)
                        )
                    }

                    let lastHeight = 0;
                    if (yAxisList[i - 1].userOptions.top) {
                        lastHeight = parseInt(yAxisList[i - 1].userOptions.top.slice(0, 2));
                    }

                    if (initHeight - lastHeight !== 20 && lastHeight !== 0) {
                        const axis_percent = initHeight + add_height
                        await yAxisList[i].update({
                            top: axis_percent.toString() + '%'
                        })
                    }
                }

            }
        }
    }

    if (callApi) {

        const { userToken, apiBaseurl } = apiData();

        const result = await axios({
            method: "post",
            url: apiBaseurl + "/game/indicate/delete",
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                indicate_id: indicate_id,
                id: competitionId
            }
        }).then(async () => {

            await removeFunc(chart);
            if (multiChart) {
                await removeFunc(multiChart);
            }

            usedIndicator = usedIndicator.filter((value, index, arr) => {
                return value.indicate_id != indicate_id
            });

            return usedIndicator;

        }).catch(error => {

            const err_msg = error.response.data;
            ElMessage({
                showClose: true,
                type: "error",
                message: err_msg.Status.Desc,
                duration: 1500
            });

            return [];
        });

        return result;

    } else {

        await removeFunc(chart);
        if (multiChart) {
            await removeFunc(multiChart);
        }

        return usedIndicator

    }
}

// 設定顯示時間範圍
export const setTimeRange = async (mode, chart, k_num = null) => {

    if (!chart) {
        setTimeout(() => {
            setTimeRange(mode, chart, k_num)
        })
    } else {
        if (mode == 'stock') {

            await chart.rangeSelector.clickButton(2, chart.rangeSelector.buttonOptions[0], true);

        } else if (mode == 'futures' || mode == 'crypto') {

            const ohlcSeries = chart.get('stock-ohlc');

            const ohlcData = ohlcSeries.xData;

            let firstTimestamp = ohlcData[0];
            let lastTimestamp = ohlcData[ohlcData.length - 1];

            if (ohlcSeries.hasGroupedData) {

                chart.xAxis[0].setExtremes(
                    firstTimestamp,
                    lastTimestamp
                );

                const groupedData = ohlcSeries.groupedData;
                // lastTimestamp = groupedData[groupedData.length - 1].category;

                let firstIndex = groupedData.length - 1 - k_num;
                if (firstIndex < 0) {
                    firstIndex = 0;
                }
                firstTimestamp = groupedData[firstIndex].category;

            } else {
                firstTimestamp = ohlcData[ohlcData.length - 1 - k_num];
            }

            chart.xAxis[0].setExtremes(
                firstTimestamp,
                lastTimestamp
            );
        }
    }
}

// Define a function to get the start date of the week for a given date

const getWeekStartDate = (timestamp) => {
    const startOfWeek = new Date(timestamp);
    startOfWeek.setHours(0, 0, 0, 0);
    const day = startOfWeek.getDay();
    const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1); // Adjust to Monday if Sunday
    startOfWeek.setDate(diff);
    return startOfWeek.getTime();
}

const getFirstWorkingDayOfMonth = (year, month) => {

    const firstDayOfMonth = new Date(year, month, 1);
    let dayOfWeek = firstDayOfMonth.getDay();

    while (dayOfWeek === 0 || dayOfWeek === 6) {
        // If the day is a Sunday (0) or Saturday (6), move to the next day
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        dayOfWeek = firstDayOfMonth.getDay();
    }

    return firstDayOfMonth.getTime();
}

// 將 flag 資料進行 groupby
export const groupFlags = async (flag_data, period) => {

    const dateToUTC = (x, period = 'day') => {

        const date = new Date(x);
        const offset = date.getTimezoneOffset();

        if (period == 'day') {

            return Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            );

        } else {

            let minute;
            if (period == 'hour') {

                if (date.getHours() == 8) {
                    minute = 45
                } else {
                    minute = 0
                }

            } else if (period == 'minute_30') {

                if (date.getHours() == 8) {
                    minute = 45
                } else {
                    if (date.getMinutes() >= 30) {
                        minute = 30;
                    } else {
                        minute = 0;
                    }
                }

            } else if (period == 'minute_15') {

                const min = date.getMinutes();
                if (min >= 0 && min <= 15) {
                    minute = 0
                } else if (min >= 15 && min < 30) {
                    minute = 15
                } else if (min >= 30 && min < 45) {
                    minute = 30
                } else if (min >= 45) {
                    minute = 45
                }

            } else if (period == 'minute_5') {

                const fiveMinutesInMs = 5 * 60 * 1000;
                const roundedTimestamp = Math.floor(date.getTime() / fiveMinutesInMs) * fiveMinutesInMs;
                minute = new Date(roundedTimestamp).getMinutes();

            }

            return Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                minute
            ) + offset * 60 * 1000;
        }
    }

    if (['week', 'month'].includes(period)) {

        const groupedFlags = flag_data.reduce((result, flag) => {

            let startDate;
            if (period == 'week') {

                startDate = getWeekStartDate(flag.x);

            } else if (period == 'month') {

                const currentDate = new Date(flag.x);
                startDate = getFirstWorkingDayOfMonth(currentDate.getFullYear(), currentDate.getMonth());
                // startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

            }

            // startDate = dateToUTC(startDate.getTime(), 'day');

            if (!result[startDate]) {
                result[startDate] = {
                    x: startDate,
                    shape: flag.shape,
                    title: flag.title,
                    color: flag.color,
                    fillColor: flag.fillColor,
                    text: flag.text,
                    flags: []
                };
            }
            result[startDate].flags.push(flag);

            return result;
        }, {});

        // Convert the grouped flags back to an array
        const result = Object.values(groupedFlags);
        console.log(result);
        return result;

    } else {

        let groupedFlagsData = [];

        let groupInterval;
        if (period == 'day') {
            groupInterval = 24 * 60 * 60 * 1000;
        } else if (period == 'hour') {
            groupInterval = 60 * 60 * 1000;
        } else if (period == 'minute_30') {
            groupInterval = 30 * 60 * 1000;
        } else if (period == 'minute_15') {
            groupInterval = 15 * 60 * 1000;
        } else if (period == 'minute_5') {
            groupInterval = 5 * 60 * 1000;
        }

        let currentGroupStart = dateToUTC(flag_data[0].x, period);
        let currentGroupData = { title: '', text: '', x: null, color: null, fillColor: null };

        for (let i = 0; i < flag_data.length; i++) {

            const flag = flag_data[i];

            if (dateToUTC(flag.x, period) >= currentGroupStart + groupInterval) {
                // Current flag is not in current group, create new group and add it to grouped data
                groupedFlagsData.push(currentGroupData);
                currentGroupData = { title: '', text: '', x: null, shape: null, color: null, fillColor: null };
                currentGroupStart = dateToUTC(flag.x, period);
            }

            // Update group data
            if (currentGroupData.x === null) {

                currentGroupData.x = dateToUTC(flag.x, period);
                currentGroupData.shape = flag.shape;
                currentGroupData.color = flag.color;
                currentGroupData.fillColor = flag.fillColor;
            }

            if (flag.title != ' ' && flag.title != '' && flag.title != null) {
                currentGroupData.title += `${flag.title},`;
            }
            if (flag.text != ' ' && flag.text != '' && flag.text != null) {
                currentGroupData.text += `${flag.text},`;
            }

        }
        if (currentGroupData.x !== null) {
            // Add the last group to grouped data
            groupedFlagsData.push(currentGroupData);
        }

        return groupedFlagsData;

    }
}

// 全螢幕模式
export const modifyScreen = (mode, kChart, kChartMutiple, openMutiple) => {

    let stock_area;
    const chart = kChart.value.chart;
    const multiChart = kChartMutiple?.value?.chart;

    if (mode == "open") {

        stock_area = document.getElementById('stock-chart-area');

        if (stock_area.offsetWidth <= 900) {
            setTimeout(() => {
                modifyScreen(mode, kChart, kChartMutiple, openMutiple);
            }, 50)
        }

        stock_area.style.height = "calc(100vh - 85px)";

        let updateWidth = stock_area.offsetWidth;
        if (openMutiple.value) {
            updateWidth /= 2;
            multiChart.setSize(
                updateWidth,
                stock_area.offsetHeight - 120,
                false
            );
        }

        chart.setSize(
            updateWidth,
            stock_area.offsetHeight - 120,
            false
        );

        document.body.style.overflow = 'hidden';

    } else if (mode == "close") {

        stock_area = document.getElementById('stock-chart-area');

        if (stock_area.offsetWidth > 900) {
            setTimeout(() => {
                modifyScreen(mode, kChart, kChartMutiple, openMutiple);
            }, 50)
        }

        stock_area.style.height = "525px";

        let updateWidth = stock_area.offsetWidth;
        if (openMutiple.value) {
            updateWidth /= 2;
            multiChart.setSize(
                updateWidth,
                525,
                false
            );
        }

        chart.setSize(
            updateWidth,
            525,
            false
        );

        document.body.style.overflow = 'auto';
    }
}

// 調整背景顏色
export const setBgColor = async (kChart, kChartMutiple, selectBgColor) => {

    const chart = kChart.value.chart;
    const multiChart = kChartMutiple?.value?.chart;
    const chartItem = document.getElementById('chart-item').firstElementChild;

    let bgColor = 'rgba(249, 219, 149, 0.75)';
    let gridLineColor = 'black';
    let itemBgColor = '#352325';
    let itemBoxShadow = '0 0 0 5px #d06440 inset, 0 0 0 11px #241b1c inset';
    let maskFill = 'rgba(0, 0, 0, 0.3)';

    if (selectBgColor == 'dark') {

        bgColor = 'rgb(15, 18, 29)';
        gridLineColor = 'rgba(255, 255, 255, 0.6)';
        itemBgColor = 'rgb(15, 18, 29)';
        itemBoxShadow = '0 0 0 5px #d06440 inset, 0 0 0 11px rgb(15, 18, 29) inset';
        maskFill = 'rgba(255, 255, 255, 0.3)';

    } else if (selectBgColor == 'white') {

        bgColor = 'white';
        gridLineColor = 'black';
        itemBgColor = 'white';
        itemBoxShadow = '0 0 0 5px #d06440 inset, 0 0 0 11px white inset';
        maskFill = 'rgba(0, 0, 0, 0.3)';

    } else {

        bgColor = 'rgba(249, 219, 149, 0.75)';
        gridLineColor = 'black';
        itemBgColor = '#352325';
        itemBoxShadow = '0 0 0 5px #d06440 inset, 0 0 0 11px #241b1c inset';
        maskFill = 'rgba(0, 0, 0, 0.3)';

    }

    await chart.update({
        chart: {
            backgroundColor: bgColor
        },
        navigator: {
            maskFill: maskFill,
            series: {
                color: gridLineColor
            }
        }
    });

    await chart.yAxis.forEach(elem => {
        elem.update({
            gridLineColor: gridLineColor
        });
    });

    if (multiChart) {
        await multiChart.update({
            chart: {
                backgroundColor: bgColor
            },
            navigator: {
                maskFill: maskFill,
                series: {
                    color: gridLineColor
                }
            }
        });
        await multiChart.yAxis.forEach(elem => {
            elem.update({
                gridLineColor: gridLineColor
            });
        });
    }

    chartItem.style.backgroundColor = itemBgColor;
    chartItem.style.boxShadow = itemBoxShadow;

}

// 取得 K 線初始 chart series data
export const getChartSeries = (ohlc, volume, flagData, mode = 'stock', slice_num = null) => {

    let lastVisiblePrice = {
        enabled: true,
        label: {
            enabled: true,
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderRadius: 5,
            padding: 4,
            align: "left",
            formatter: function (value) {
                if (mode == 'futures') {
                    return value.toFixed(2).slice(slice_num,);
                } else if (mode == 'crypto') {
                    return value.toFixed(4)
                } else {
                    return value.toFixed(2)
                }
            }
        },
    }

    return [{
        type: 'candlestick',
        id: 'stock-ohlc',
        name: 'Stock Price',
        data: ohlc,
        lastVisiblePrice: lastVisiblePrice,
        turboThreshold: 2000
    }, {
        type: 'column',
        id: 'stock-volume',
        name: 'Volume',
        data: volume,
        yAxis: 1,
        turboThreshold: 2000
    }, {
        type: 'flags',
        id: 'stock-flags',
        data: flagData,
        style: {
            fontSize: 0
        },
        onSeries: 'stock-ohlc',
        width: 5,
        height: 5,
        enableMouseTracking: false
    }]
}

// 新增多週期圖
export const addMultiple = async (
    kChart, kChartMutiple, openMutiple,
    indicateSetting, usedIndicator, upColor,
    downColor, selectMultiple, multipleAddData,
    mode = 'stock', k_num = null) => {

    const userStore = useUserStore();
    const isGenesis = userStore.isGenesis;

    const commonStore = useCommonStore();
    const windowWidth = commonStore.windowWidth;

    const gameStore = useGameStore();
    const game_permission = gameStore.game_permission;
    const game_params = gameStore.game_params;

    let multiPeriod = false;
    if (game_permission.multiPeriod) {
        multiPeriod = true;
    }

    if (!isGenesis && !multiPeriod) {

        ElMessage({
            message: "You don't have permission.",
            showClose: true,
            duration: 1500
        });

        if (!game_permission.multiPeriod && game_params.openMultiple) {
            gameStore.game_params.openMultiple = false;
            openMutiple.value = false;
            await setGameParams();
        }

        return;
    }

    openMutiple.value = true;
    const chart = kChart.value.chart;

    setTimeout(async () => {

        const multiChart = kChartMutiple.value.chart;

        const dailyPrice = chart.series[0].options.data;
        const volumeY = chart.series[1].yData;

        for (let i = 0; i < dailyPrice.length; i++) {
            if (mode == 'stock') {
                dailyPrice[i].push(volumeY[i] * 1000)
            } else {
                dailyPrice[i].push(volumeY[i])
            }
        }

        let groupedPrice;
        if (selectMultiple.value == 'week') {
            groupedPrice = groupDailytoWeekly(dailyPrice);
        } else if (selectMultiple.value == 'month') {
            groupedPrice = groupDailytoMonthly(dailyPrice);
        } else if (selectMultiple.value == 'hour') {
            groupedPrice = groupMinutetoHour(dailyPrice);
        } else if (selectMultiple.value == 'day') {
            groupedPrice = groupHourtoDaily(dailyPrice);
        }
        const groupedData = getOHLCData(groupedPrice, upColor, downColor, mode);

        await multiChart.series[0].update({
            data: groupedData.ohlc
        });

        await multiChart.series[1].update({
            data: groupedData.volume
        });

        // 更新 flag
        const flagData = chart.series[2].data;
        const multipleFlags = await groupFlags(flagData, selectMultiple.value);

        await multiChart.series[2].update({
            data: multipleFlags
        });

        if (windowWidth > 767) {

            const updateWidth = chart.chartWidth / 2;
            chart.update({
                chart: {
                    width: updateWidth
                }
            });

            document.getElementsByClassName('k-chart-multiple')[0].style.width = '100%';
            document.getElementsByClassName('k-chart-multiple')[0].style.height = '100%';
            document.getElementsByClassName('k-chart-multiple')[0].style.marginLeft = '10px';

            await multiChart.update({
                chart: {
                    width: updateWidth,
                    height: chart.chartHeight
                }
            });

        } else {

            const updateHeight = chart.chartHeight / 1.4;
            await chart.update({
                chart: {
                    height: updateHeight
                },
                navigator: {
                    height: 20,
                    handles: {
                        height: 20
                    }
                }
            });

            document.getElementsByClassName('k-chart-multiple')[0].style.width = '100%';
            document.getElementsByClassName('k-chart-multiple')[0].style.height = updateHeight.toString() + 'px';
            document.getElementsByClassName('k-chart-multiple')[0].style.marginTop = '10px';

            await multiChart.update({
                chart: {
                    width: chart.chartWidth,
                    height: updateHeight
                },
                navigator: {
                    height: 20,
                    handles: {
                        height: 20
                    }
                }
            });

        }

        await initIndicator(
            multiChart, indicateSetting.value, usedIndicator.value,
            upColor.value, downColor.value, true
        );
        // groupFlags(flagData, se)

        await setTimeRange(mode, multiChart, k_num);

    }, 100)
}

export const removeMultiple = async (kChart, kChartMutiple, openMutiple) => {

    const userStore = useUserStore();
    const isGenesis = userStore.isGenesis;

    const commonStore = useCommonStore();
    const windowWidth = commonStore.windowWidth;

    const gameStore = useGameStore();
    const game_permission = gameStore.game_permission;

    let multiPeriod = false;
    if (game_permission.multiPeriod) {
        multiPeriod = true;
    }

    if (!isGenesis && !multiPeriod) {
        ElMessage({
            message: "You don't have permission.",
            showClose: true,
            duration: 1500
        });
        return;
    }

    openMutiple.value = false;

    const chart = kChart.value.chart;
    const multiChart = kChartMutiple.value.chart;

    if (windowWidth > 767) {

        const updateWidth = chart.chartWidth * 2
        chart.update({
            chart: {
                width: updateWidth
            }
        });

        document.getElementsByClassName('k-chart-multiple')[0].style.width = '0%';
        document.getElementsByClassName('k-chart-multiple')[0].style.marginLeft = '0px';

        multiChart.update({
            chart: {
                width: updateWidth
            }
        });

    } else {

        chart.update({
            chart: {
                height: 525
            },
            navigator: {
                height: 60,
                handles: {
                    height: 40,
                    width: 10
                }
            }
        });

        document.getElementsByClassName('k-chart-multiple')[0].style.width = '0%';
        document.getElementsByClassName('k-chart-multiple')[0].style.height = '0px';
        document.getElementsByClassName('k-chart-multiple')[0].style.marginTop = '0px';

    }
}

export const changeMultiple = async (kChart, kChartMutiple, selectMultiple, multipleFlags, usedIndicator, mode = 'stock') => {

    const multiChart = kChartMutiple.value.chart;
    const chart = kChart.value.chart;

    if (['week', 'month', 'hour', 'day'].includes(selectMultiple.value) || selectMultiple.value.includes('minute')) {

        console.log(selectMultiple.value);

        const upColor = ref(chart.series[0].options.upColor);
        const downColor = ref(chart.series[0].options.color);

        const dailyPrice = chart.series[0].options.data;
        const volumeY = chart.series[1].yData;

        for (let i = 0; i < dailyPrice.length; i++) {
            if (mode == 'stock') {
                dailyPrice[i].push(volumeY[i] * 1000)
            } else {
                dailyPrice[i].push(volumeY[i])
            }
        }

        let groupedPrice;
        if (selectMultiple.value == 'week') {

            groupedPrice = groupDailytoWeekly(dailyPrice);

        } else if (selectMultiple.value == 'month') {

            groupedPrice = groupDailytoMonthly(dailyPrice);

        } else if (selectMultiple.value == 'hour') {

            groupedPrice = groupMinutetoHour(dailyPrice);

        } else if (selectMultiple.value.includes('minute')) {

            const minNum = selectMultiple.value.split('_')[1];
            groupedPrice = groupMinutetoMinute(dailyPrice, parseInt(minNum));

        } else if (selectMultiple.value == 'day') {

            groupedPrice = groupHourtoDaily(dailyPrice);

        }

        const groupedData = getOHLCData(groupedPrice, upColor, downColor, mode);

        for (let i = 0; i < usedIndicator.value.length; i++) {
            const info = usedIndicator.value[i];
            const indicate_id = info['indicate_id'];
            const indicate_type = info['indicate_type'];
            if (multiChart.get(indicate_id)) {
                await deleteIndicator(multiChart, indicate_id, indicate_type, usedIndicator.value, null, false);
            }
        }

        await multiChart.series[0].update({
            data: groupedData.ohlc
        });

        await multiChart.series[1].update({
            data: groupedData.volume
        });

        console.log(multiChart.series);

        const newFlagData = chart.series[2].data;
        multipleFlags.value = await groupFlags(newFlagData, selectMultiple.value);

        await multiChart.series[2].update({
            data: multipleFlags.value
        });

        await multiChart.redraw();

    }

}

export const getPermission = async () => {
    const gameStore = useGameStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + "/permission",
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {

        const result = response.data.Result;
        gameStore.game_permission = result;

    }).catch(error => {

        const errorMsg = error.response.data;

        ElMessage({
            showClose: true,
            message: errorMsg.Status.Desc,
            type: "error",
            duration: 1500
        });

    })
}

export const switchChart = () => {

    const main = document.getElementById('k-chart-main');
    const multi = document.getElementById('k-chart-multiple');

    if (parseInt(main.style.order) == 1) {

        main.style.order = 2;
        multi.style.order = 1;
        multi.style.marginRight = '10px';
        multi.style.marginLeft = '0px';

    } else {

        main.style.order = 1;
        multi.style.order = 2;
        multi.style.marginRight = '0px';
        multi.style.marginLeft = '10px';

    }

}