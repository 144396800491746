import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'home'
    }
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import('../views/LeaderBoard.vue'),
    meta: {
      title: 'leaderboard'
    }
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('../views/user/UserPage.vue'),
    meta: {
      title: 'mypage'
    }
  },
  {
    path: '/self_info',
    name: 'self_info',
    component: () => import('../views/user/editSelfInfo.vue'),
    meta: {
      title: 'self_info'
    }
  },
  {
    path: '/manage_friend',
    name: 'manage_friend',
    component: () => import('../views/user/ManageFriend.vue'),
    meta: {
      title: 'manage_friend'
    }
  },
  {
    path: '/manage_sub',
    name: 'manage_sub',
    component: () => import('../views/user/ManageSubscribe.vue'),
    meta: {
      title: 'manage_sub'
    }
  },
  {
    path: '/user/:gamer_id',
    name: 'userpage',
    component: () => import('../views/user/UserPage.vue')
  },
  {
    path: '/forum',
    name: 'forum',
    component: () => import('../views/forum/ForumView.vue'),
    meta: {
      title: 'forum'
    }
  },
  {
    path: '/forum/rule',
    name: 'forum_rule',
    component: () => import('../views/forum/ArticleRule.vue'),
    meta: {
      title: 'forum_rule'
    }
  },
  {
    path: '/forum/announcement',
    name: 'announcement',
    component: () => import('../views/forum/Announcement.vue'),
    meta: {
      title: 'announcement'
    }
  },
  {
    path: '/article/:article_id',
    name: 'article_detail',
    component: () => import('../views/forum/ArticleDetail.vue'),
  },
  {
    path: '/create_article',
    name: 'create_article',
    component: () => import('../views/forum/CreateArticle.vue'),
    meta: {
      title: 'create_article'
    }
  },
  {
    path: '/edit_article',
    name: 'edit_article',
    component: () => import('../views/forum/EditArticle.vue'),
    meta: {
      title: 'edit_article'
    }
  },
  {
    path: '/game_lobby',
    name: 'game_lobby',
    component: () => import('../views/GameLobby.vue'),
    meta: {
      title: 'game_lobby'
    }
  },
  {
    path: '/mission/chapter',
    name: 'mission_chapter',
    component: () => import('../views/mission_mode/ChapterPage.vue'),
    meta: {
      title: 'mission_chapter'
    }
  },
  {
    path: '/mission/chapter/:chapter_id',
    name: 'mission_level',
    component: () => import('../views/mission_mode/LevelPage.vue')
  },
  {
    path: '/mission/level/game',
    name: 'level_game',
    component: () => import('../views/mission_mode/LevelGameIn.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/mission/level/game/result',
    name: 'level_game_result',
    component: () => import('../views/mission_mode/LevelGameResult.vue'),
    meta: {
      title: 'level_game_result'
    }
  },
  {
    path: '/wishing_tree',
    name: 'wishing_tree',
    component: () => import('../views/service/WishingTree.vue'),
    meta: {
      title: 'wishing_tree'
    }
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('../views/service/BankView.vue'),
    meta: {
      title: 'bank'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'about'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue'),
    meta: {
      title: 'privacy'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue'),
    meta: {
      title: 'search'
    },
    props: route => ({ query: route.query })
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/game/GameInView.vue'),
    meta: {
      title: 'game'
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/game_detail',
    name: 'game_detail',
    component: () => import('../views/game/GameDetail.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/game_over',
    name: 'game_over',
    component: () => import('../views/game/GameOverView.vue'),
    meta: {
      title: 'game_over'
    },
    props: route => ({ query: route.query })
  },
  {
    path: '/notify',
    name: 'notify',
    component: () => import('../views/user/NotifyView.vue'),
    meta: {
      title: 'notify'
    }
  },
  {
    path: '/mail',
    name: 'mail',
    component: () => import('../views/user/MailView.vue'),
    meta: {
      title: 'mail'
    }
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: () => import('../views/auth/ResetPassword.vue'),
    meta: {
      title: 'reset_password'
    },
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: {
      title: 'forgot_password'
    }
  },
  {
    path: '/set_password/key/:token',
    name: 'set_password_key',
    component: () => import('../views/auth/SetPasswordKey.vue'),
    meta: {
      title: 'set_password_key'
    }
  },
  {
    path: '/set_password',
    name: 'set_password',
    component: () => import('../views/auth/SetPassword.vue'),
    meta: {
      title: 'set_password'
    }
  },
  {
    path: '/line/auth',
    name: 'LineAuth',
    component: () => import('../views/auth/LineAuth.vue'),
    meta: {
      title: 'LineAuth'
    }
  },
  {
    path: '/discord',
    name: 'discordRedirect',
    component: () => import('../views/auth/DiscordAuth.vue'),
    meta: {
      title: 'DiscordAuth'
    }
  },
  {
    path: '/discord/xnft',
    name: 'discordXnftRedirect',
    component: () => import('../views/auth/DiscordXnftAuth.vue'),
    meta: {
      title: 'DiscordAuth'
    }
  },
  {
    path: '/discord/complete',
    name: 'discordXnftComplete',
    component: () => import('../views/auth/DiscordXnftAuthComplete.vue'),
    meta: {
      title: 'DiscordAuth'
    }
  },
  // {
  //   path: '/mint',
  //   name: 'mintPage',
  //   component: () => import('../views/service/MintPage.vue'),
  //   meta: {
  //     title: 'mintPage'
  //   }
  // },
  // {
  //   path: '/claim',
  //   name: 'claimPage',
  //   component: () => import('../views/service/ClaimPage.vue'),
  //   meta: {
  //     title: 'claimPage'
  //   }
  // },
  {
    path: '/staking/:identifier',
    name: 'stakingPage',
    component: () => import('../views/service/StakingPage.vue'),
    meta: {
      title: 'stakingPage'
    }
  },
  {
    path: '/competition/home',
    name: 'competitionHome',
    component: () => import('../views/competition/HomePage.vue'),
    meta: {
      title: 'competitionHome'
    }
  },
  {
    path: '/competition/info/:competition_id',
    name: 'competitionInfo',
    component: () => import('../views/competition/InfoPage.vue'),
    meta: {
      title: 'competitionInfo'
    }
  },
  {
    path: '/competition/rank/:competition_id',
    name: 'competitionRank',
    component: () => import('../views/competition/RankPage.vue'),
    meta: {
      title: 'competitionRank'
    }
  },
  {
    path: '/competition/game',
    name: 'competitionGame',
    component: () => import('../views/game/GameInView.vue'),
    meta: {
      title: 'game'
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/competition/game_over',
    name: 'competitionGameOver',
    component: () => import('../views/game/GameOverView.vue'),
    meta: {
      title: 'game_over'
    },
    props: route => ({ query: route.query })
  },
  {
    path: '/rewards',
    name: 'gameRewards',
    component: () => import('../views/game/GameReward.vue'),
    meta: {
      title: 'gameRewards'
    },
    props: route => ({ query: route.query })
  },
  // {
  //   path: '/referral',
  //   name: 'referralPage',
  //   component: () => import('../views/service/ReferralView.vue'),
  //   meta: {
  //     title: 'referralPage'
  //   },
  //   props: route => ({ query: route.query })
  // },
  {
    path: '/app',
    redirect: '/'
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
