<template>
    <common-modal ref="earlyEnd" :can-close="true" :tit="$t('modal.gameOver.tit')" class="md">
        <template v-slot:infoArea>
            <h2 class="text-center" style="color: rgb(155,24,51);">{{ $t('modal.gameOver.desc.1') }}</h2>
            <h4 class="text-center">{{ $t('modal.gameOver.desc.2') }}</h4>
            <el-button id="end-btn" type="coffee" class="blockBtn margin-bottom-20" :loading="resultDisabled"
                @click="goEndGame()">{{ $t('modal.gameOver.endBtn') }}</el-button>
        </template>
    </common-modal>
</template>

<script>
import { ref } from 'vue';
import CommonModal from '../CommonModal.vue';
import { useRouter } from 'vue-router';

export default {
    name: 'gameOverModal',
    props: {
        toggle: {
            default: false,
            required: true
        },
        mode: {
            default: 'free',
            required: false
        },
        competitionId: {
            default: null,
            required: false
        }
    },
    components: {
        CommonModal
    },
    setup(props) {

        const router = useRouter();

        const competitionId = ref(props.competitionId);
        const resultDisabled = ref(false);

        const goEndGame = () => {

            resultDisabled.value = true;

            if (competitionId.value) {

                router.push({
                    path: '/competition/game_over',
                    query: {
                        competition_id: competitionId.value
                    }
                });

            } else if (props.mode == 'free') {

                router.push({path: '/game_over'});

            } else if (props.mode == 'mission') {

                window.onbeforeunload = null;
                window.location.href = '/mission/level/game/result';
                
            }
            
        }

        return {
            resultDisabled,
            goEndGame
        }
    },
    watch: {
        toggle: function () {
            this.toggleModal('earlyEnd');
        }
    },
    methods: {
        toggleModal: function (t) {
            this.$refs[t].toggle = !this.$refs[t].toggle;
        },
    }
}

</script>

<style scoped>
#end-btn {
    font-size: 16px;
    height: 40px;
}
</style>